import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/style.css';

const ModalAdware = ({ show, handleClose }) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form data without submitting to the server
    // const form = e.target;
    // const data = new FormData(form);
    // You can add any custom logic here to handle the form data
    // console.log('Form data:', Object.fromEntries(data.entries()));
    navigate('/thankyou');
  };

  return (
    <>
      <div className={`modal-backdrop ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}></div>
      <div className={`modal fade-xl mt-20 ${show ? 'show' : ''}`} role="dialog" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className='modal-content'>
            <div className="modal-header">
              <h1 className='font-bold text-2xl'>Request Callback</h1>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="styledInput"
                    placeholder="Enter Your Name"
                    required
                  />
                  <input
                    type="number"
                    className="styledInput"
                    name="mobile"
                    pattern="[0-9]{10}"
                    autoComplete="off"
                    maxLength="10"
                    placeholder="Enter Mobile Number"
                    aria-describedby="basic-addon1"
                    required
                  />
                  <input
                    type="text"
                    className="styledInput"
                    name="msg"
                    placeholder="Enter Your Message"
                    required
                  />
                </div>
                <input type="hidden" name="clientMailID" value="bookmysir@gmail.com" />
                <input name="website" type="hidden" value="bookmysir.com/thanku.html" />
                <input name="loginID" type="hidden" value="1349" />
                <input name="orderID" type="hidden" value="1506" />
                <div className='flex justify-between'>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-block"
                    >
                      <span className="fa fa-paper-plane"></span> Submit
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-block"
                      onClick={handleClose}
                    >
                      <span className="fa fa-paper-plane"></span> Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAdware;
