import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import FloatingCallButton from '../components/homepage/FloatingCallButton';

function MyLearningPage() {
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const userId = localStorage.getItem('userID');

  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      if (!userId) {
        console.error('User ID not found in localStorage');
        return;
      }

      const purchasedCoursesRef = collection(firestore, 'purchasedCourses');
      const q = query(purchasedCoursesRef, where('userId', '==', userId));

      try {
        const querySnapshot = await getDocs(q);
        const courses = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.courses.forEach(course => {
            courses.push({ name: course.name, link: course.link });
          });
        });
        
        console.log('Fetched courses:', courses); // Logging fetched courses for debugging
        setPurchasedCourses(courses);
      } catch (error) {
        console.error('Error fetching purchased courses: ', error);
      }
    };

    fetchPurchasedCourses(); // Always fetch courses when component mounts

  }, [userId]);

  return (
    <>
    <section className='mt-32 md:pl-32'>
      <h1 className='my_learning_title'>My Learning</h1>
      <div className='grid md:grid-cols-3 grid-cols-1 md:gap-12 gap-6 md:pl-0 pl-4'>
        {purchasedCourses.map((course, index) => (
          <div key={index} className='course-name'>
            <h2>{course.name}</h2>
            <a href={course.link} target="_blank" rel="noopener noreferrer">{course.link}</a>
          </div>
        ))}
        {purchasedCourses.length === 0 && (
          <p>No purchased courses found.</p>
        )}
      </div>
    </section>

    <FloatingCallButton/>
    </>
  );
}

export default MyLearningPage;
