// CartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, firestore } from '../../firebase';
import { collection, addDoc, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [newlyAddedItems, setNewlyAddedItems] = useState([]); // State for newly added items
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchCartItems(user.uid);
        fetchPurchasedCourses(user.uid); // Fetch purchased courses
      } else {
        setUserId(null);
        setCart([]);
        setPurchasedCourses([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCartItems = async (uid) => {
    const cartRef = collection(firestore, 'cartItems');
    const q = query(cartRef, where('userId', '==', uid));

    try {
      const querySnapshot = await getDocs(q);
      const cartItemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCart(cartItemsData);
    } catch (e) {
      console.error('Error fetching cart items: ', e);
    }
  };

  const fetchPurchasedCourses = async (uid) => {
    const purchasedCoursesRef = collection(firestore, 'purchasedCourses');
    const q = query(purchasedCoursesRef, where('userId', '==', uid));

    try {
      const querySnapshot = await getDocs(q);
      const purchasedCoursesData = querySnapshot.docs.map(doc => doc.data());
      setPurchasedCourses(purchasedCoursesData);
    } catch (e) {
      console.error('Error fetching purchased courses: ', e);
    }
  };

  const storage = getStorage();

  const addToCart = async (course) => {
    if (userId) {
      // Handle file upload if there's a file in the course object
      let courseWithFileURL = { ...course, userId };
      if (course.file) {
        const file = course.file;
        const storageRef = ref(storage, `course_files/${uuidv4()}`);
        try {
          await uploadBytes(storageRef, file);
          const fileURL = await getDownloadURL(storageRef);
          courseWithFileURL = { ...courseWithFileURL, fileURL };
          delete courseWithFileURL.file;  // Remove the file object from the course data
        } catch (e) {
          console.error('Error uploading file: ', e);
          return;
        }
      }

      try {
        const docRef = await addDoc(collection(firestore, 'cartItems'), courseWithFileURL);
        const newCartItem = { id: docRef.id, ...courseWithFileURL };
        setCart([...cart, newCartItem]);
        setNewlyAddedItems([...newlyAddedItems, newCartItem]); // Add newly added item to the state
        setCart([...cart, course]);
      } catch (e) {
        console.error('Error adding item to cart: ', e);
      }
    } else {
      alert('Please log in to add items to the cart');
    }
  };

  const removeFromCart = async (courseName) => {
    if (userId) {
      const cartRef = collection(firestore, 'cartItems');
      const q = query(cartRef, where('userId', '==', userId), where('name', '==', courseName));
      try {
        const querySnapshot = await getDocs(q);
        const batch = writeBatch(firestore);
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();
        setCart(cart.filter(item => item.name !== courseName));
        setNewlyAddedItems(newlyAddedItems.filter(item => item.name !== courseName)); // Remove item from newly added items state
      } catch (e) {
        console.error('Error removing item from cart: ', e);
      }
    }
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  const clearCart = () => {
    setCart([]);
    setNewlyAddedItems([]); // Clear newly added items
  };
  

  return (
    <CartContext.Provider value={{ cart, purchasedCourses, newlyAddedItems, addToCart, removeFromCart, getTotalPrice, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
