import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { CartContext } from '../components/contexts/CartContext';
import FloatingCallButton from '../components/homepage/FloatingCallButton';

function Checkout() {
  const [studentDetails, setStudentDetails] = useState(null);
  const navigate = useNavigate();
  const { clearCart, setPurchasedCourses } = useContext(CartContext);

  useEffect(() => {
    // Retrieve student details from localStorage
    const details = localStorage.getItem('studentDetails');
    if (details) {
      const parsedDetails = JSON.parse(details);
      // Ensure userId is set
      if (!parsedDetails.userId) {
        parsedDetails.userId = localStorage.getItem('userID'); // Assuming userID is stored in localStorage
      }
      setStudentDetails(parsedDetails);
    }
  }, []);

  // Filter out already purchased courses
  const getFilteredCartDetails = () => {
    return studentDetails?.cartDetails || [];
  };

  const getTotalPrice = () => {
    return getFilteredCartDetails().reduce((total, course) => total + course.price, 0);
  };

  const savePurchasedCourses = async () => {
    try {
      if (!studentDetails || !studentDetails.userId) {
        throw new Error('User ID is missing');
      }

      const filteredCourses = getFilteredCartDetails().map(course => ({
        name: course.name,
        link: course.link
      })).filter(course => course.name && course.link);

      if (filteredCourses.length === 0) {
        throw new Error('No valid courses to save');
      }

      const details = collection(firestore, 'purchasedCourses');
      await addDoc(details, {
        userId: studentDetails.userId,
        courses: filteredCourses,
        purchaseDate: new Date(),
      });
      // Update the purchased courses context
      setPurchasedCourses(prev => [...prev, ...filteredCourses]);
    } catch (error) {
      console.error('Error saving purchased courses: ', error);
      // Handle error (e.g., show a message to the user)
    }
  };

  const handlePayment = async () => {
    try {
      const totalPrice = getTotalPrice();
      if (totalPrice <= 0) {
        throw new Error('Total price is invalid or zero');
      }

      const options = {
        amount: totalPrice, // Amount in paisa (100x amount for INR)
        currency: 'INR',
        receipt: 'bookmysir',
        payment_capture: 1,
      };

      const response = await fetch('https://localhost:5000/razorpay/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      }).then((res) => res.json());

      const razorpayOptions = {
        key: 'rzp_test_O3D0Wxoued9YVG', // Replace with your Razorpay test key
        amount: totalPrice * 100,
        currency: 'INR',
        name: 'bookmysir',
        description: 'Payment for online course',
        order_id: response.id,
        handler: async function (response) {
          console.log(response);
          await savePurchasedCourses();
          clearCart();
          localStorage.removeItem('studentDetails');
          setStudentDetails(null); // Clear state
          navigate('/mylearning');
        },
        prefill: {
          name: studentDetails?.fullName || '',
          email: studentDetails?.emailAddress || '',
          contact: studentDetails?.phoneNumber || '',
        },
        notes: {
          address: 'Payment',
        },
        theme: {
          color: '#F37254',
        },
      };

      const rzp1 = new window.Razorpay(razorpayOptions);
      rzp1.open();
    } catch (error) {
      console.error('Error during payment: ', error);
      // Handle error (e.g., show a message to the user)
    }
  };

  return (
    <>
    <section className='mt-32 md:pl-32 md:pr-32 pl-4 pr-4'>
      <h1 className='condition_title'>Checkout <span className='condition'>Details</span></h1>
      {studentDetails ? (
        <div className='grid md:grid-cols-2 grid-cols-1 md:gap-80 md:pl-0 pl-4'>
          <div>
            <div className='mb-10'>
              <h2 className='small_title'>Student Details:</h2>
              <p><b>Name:</b> {studentDetails.fullName}</p>
              <p><b>Email:</b> {studentDetails.emailAddress}</p>
              <p><b>Phone:</b> {studentDetails.phoneNumber}</p>
              <p><b>School/College:</b> {studentDetails.educationName}</p>
            </div>
            <h2 className='small_title'>Order Details:</h2>
            {getFilteredCartDetails().map((course, index) => (
              <div key={index} className='mb-4'>
                <h1 className='font-bold text-xl'>{course.name}</h1>
                <h1>₹{course.price}</h1>
              </div>
            ))}
          </div>
          <div>
            <h2 className='small_title'>Summary</h2>
            <div className='grid md:grid-cols-2 grid-cols-1 mb-4'>
              <div>
                <h2 className='font-medium'>Total:</h2>
              </div>
              <div>
                <h2 className='font-medium'>₹{getTotalPrice()}</h2>
              </div>
            </div>
            <p className='md:mr-28 mr-10 text-sm'>By completing your purchase you agree to the <Link to="/termsandconditions">Terms & Conditions</Link></p>
            <button type='button' className='checkout_button' onClick={handlePayment}>Complete Checkout</button>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </section>

    <FloatingCallButton/>
    </>
  );
}

export default Checkout;
