import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/style.css'; // We'll create this CSS file next

const FloatingCallButton = () => {
  return (
    <a href="tel:+91-9355078783" className="floating-call-button">
      <FontAwesomeIcon icon={faPhone} />
    </a>
  );
};

export default FloatingCallButton;
