// src/components/ThankYou.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FloatingCallButton from '../components/homepage/FloatingCallButton';

function ThankYou() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);
    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <>
    <div className='mt-32'>
      <div className='pt-20 pb-20'>
        <h1 className='text-center font-bold text-8xl'>Thank You</h1>
        <h1 className='text-center font-medium'>Our team will get back to you soon.</h1>
      </div>
    </div>

    <FloatingCallButton/>
    </>
  );
}

export default ThankYou;
